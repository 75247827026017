<template>
  <div>
    <div class="header" :class="navBarFixed ? 'navBarWarp' : ''">
      <div class="container header-box">
        <div class="header-left">
          <img src="../assets/CreativeStation.svg" alt="" />
        </div>
        <div class="header-right">
          <ul>
            <li
              :class="navCurrent == index ? 'navActive' : ''"
              v-for="(item, index) in nav"
              :key="item.id"
              @click="handleNav(index)"
            >
              {{ item.navTitle }}
            </li>
          </ul>
          <div class="language">
            <img src="../assets/globe.png" alt="" />
            <span>中文</span>
            <span class="center">/</span>
            <span>English</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 介绍 -->
    <div class="introduce container" :id="'abc' + 0">
      <p>欢迎来到C宇宙</p>
      <p>一家实物 & NFT 同步发售的平台</p>
      <p>一个以艺术、文化、娱乐创作为主题的开放式宇宙</p>
    </div>
    <!-- 英文TITLE -->
    <div class="Creative">CREATIVE STATION</div>
    <!-- 简介 -->
    <div class="brief container" :id="'abc' + 1">
      <p>初识C宇宙</p>
      <p class="info">
        C宇宙作为实现全真元宇宙的先行实践者，承载着链接虚拟与现实，过去与未来的重要文化使命。它将在不同的外部环境下实时调整，努力践行其愿景、使命、价值观，让更多的人了解元宇宙，参与元宇宙。
      </p>
      <button class="look" @click="lookBook">点击查看白皮书</button>
      <div class="brief-list">
        <div class="list-one" v-for="item in briefList" :key="item.id">
          <p>{{ item.title }}</p>
          <p>{{ item.dec }}</p>
        </div>
      </div>
    </div>
    <!-- 生态 -->
    <div class="ecology container" :id="'abc' + 2">
      <p>C宇宙的生态</p>
      <p class="info">
        作为以NFT交易为核心入口的可进入式宇宙，其生态的构成和多样性决定了其能否作为“宇宙”的核心考量。
      </p>
      <button class="look" @click="lookBook">点击查看白皮书</button>
      <div class="ecology-box">
        <div class="ecology-list" v-for="item in ecologyList" :key="item.id">
          <p>{{ item.title }}</p>
          <p>
            {{ item.dec }}
          </p>
        </div>
      </div>
    </div>
    <!-- 核心技术 -->
    <div class="core container" :id="'abc' + 3">
      <div class="core-header">
        <div class="core-left">
          <p>UE5开发可进入宇宙</p>
          <p>
            在WEB
            GL的基础上，为了加深用户沉浸式体验和场景效果。我们已经开始基于UE5Nanite的可进入式宇宙开发
            。试图创造一个高沉浸、低延迟的可进入宇宙。
          </p>
        </div>
        <div class="core-right">
          <p>创新性的技术架构 - AI激光扫描算法(CSSA)</p>
          <p>
            移动互联网用户的进入方式是卡片式的，它是基于文字字段和图片以及视频来进行展示和交互的，它属于二维空间。元宇宙时代是基于三维的，无论我们进入什么主题的元宇宙中，我们均需一个三维角色。以往的三维角色是需要巨大的时间和经济成本的，我们创新性的用一套AI激光扫描算法解决这一过程。
          </p>
        </div>
      </div>
      <p class="main-text">AI 激光扫描算法与传统数字生产的优劣势比较</p>
      <div class="table">
        <table>
          <thead>
            <th></th>
            <th>技术流程</th>
            <th>硬件设备</th>
            <th>时间</th>
            <th>成本</th>
          </thead>
          <tbody>
            <tr>
              <td>传统数据人生产</td>
              <td>原画－建模－骨骼绑定－动作捕捉－渲染</td>
              <td>PC电脑、可穿戴设备</td>
              <td>５个专业部门，一个月</td>
              <td>100万人民币</td>
            </tr>
            <tr>
              <td>AI激光扫描算法</td>
              <td>二维图片－三维扫描－资产</td>
              <td>手机</td>
              <td>１分钟</td>
              <td>边际成本逐渐变小</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- 团队介绍 -->
    <div class="team container" :id="'abc' + 4">
      <p>团队介绍</p>
      <p class="team-message">
        C宇宙的团队分布在内容生产、NFT与区块链、算法、社群运营等诸多部门，团队90%均为90后团队。分布在北京、上海、成都、重庆、加拿大、美国、新加坡，是“SCRUM”团队的典型代表。团队成员均在各自领域建立了领先优势，90%以上毕业于世界前100大学，平均研究生以上学历。
        以算法团队举例：
      </p>
      <div class="examples">
        <div class="examples-left">
          <p>
            Zihao Deng
            <span>Carnegie Mellon University - Computer Science</span>
          </p>
          <div class="examples-box">
            <img src="../assets/icon.png" alt="" />
            <p>
              ML research in CMU Computational Biology Xu lab/Publication:
              Cryo-shift：reducing domain shift in cryo-electron subtomograms
              with unsupervised domain adaptation and randomization.
            </p>
          </div>
          <div class="examples-box">
            <img src="../assets/icon.png" alt="" />
            <p>
              SE internship at
              华为分布式与并行计算实验室。研究新一代云计算和边缘计算技术，探索更轻量化的云计算架构以及部分技术在severless场景下的应用前景
            </p>
          </div>
          <div class="examples-box">
            <img src="../assets/icon.png" alt="" />
            <p>
              (目前所在lab) ML research in CMU LTI Multicomp lab：Develop
              interpreta-tion algorithms for analyzing and reasoning about
              Multimodal ML models.
            </p>
          </div>
        </div>
        <div class="examples-right">
          <p>
            Yudong Liu
            <span>Carnegie Mellon University - Computer Science</span>
          </p>
          <div class="examples-box">
            <img src="../assets/icon.png" alt="" />
            <p>
              ML research in CMU LTI Multicomp Lab：项目名称MirrorNet，利用LSTM
              和CNN 多层autoencoder 进行多模态数据特征编码
            </p>
          </div>
          <div class="examples-box">
            <img src="../assets/icon.png" alt="" />
            <p>
              (目前所在lab) ML research in CMU LTI Multicomp Lab：progressive
              image 2 text description generation
            </p>
          </div>
          <div class="examples-box">
            <img src="../assets/icon.png" alt="" />
            <p>
              (目前所在lab) ML research in CMU LTI Multicomp Lab：progressive
              image 2 text description generation
            </p>
          </div>
          <div class="examples-box">
            <img src="../assets/icon.png" alt="" />
            <p>
              ML research in CMU Computational Biology Mohimani Lab: 项目名称
              Alpha-Fold，利用AlphaFold
              神经网络构造蛋白质结构，并用神经网络计算其和不同氨基酸的成键相性。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 媒体 -->
    <div class="media" :id="'abc' + 5">
      <p>社交媒体</p>
      <div class="media-item">
        <div class="media-box">
          <img src="../assets/book.svg" alt="" />
          <div>小红书</div>
        </div>
        <div class="media-box">
          <img src="../assets/wechat.svg" alt="" />
          <div>微信</div>
        </div>
        <div class="media-box">
          <img src="../assets/weibo.svg" alt="" />
          <div>微博</div>
        </div>
        <div class="media-box">
          <img src="../assets/green.svg" alt="" />
          <div>微博</div>
        </div>
      </div>
    </div>
    <!-- 页脚 -->
    <div class="footer">
      Copyright  2022 绮宇宙（重庆）科技有限公司 版权所有 <a class="active-a" href="https://beian.miit.gov.cn/#/Integrated/recordQuery"> 渝ICP备2022002577号-1</a>
    </div>

    <div class="share">
      <div>
        <span>扫描二维码</span>
        <p>下载C宇宙APP</p>
      </div>
      <div class="img">
        <img src="../assets/image.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      navBarFixed: false,
      navCurrent: 0,
      nav: [
        {
          id: 1,
          navTitle: "首页",
        },
        {
          id: 2,
          navTitle: "C宇宙简介",
        },
        {
          id: 3,
          navTitle: "C宇宙生态",
        },
        {
          id: 4,
          navTitle: "核心技术",
        },
        {
          id: 5,
          navTitle: "团队介绍",
        },
        {
          id: 6,
          navTitle: "社交媒体",
        },
      ],
      briefList: [
        {
          id: 0,
          title: "C宇宙的定义",
          dec: "C宇宙是一个分阶段的切入全真元宇宙的交易平台，通过一系列技术逐步实现全真元宇宙。本阶段它将集中在虚拟体验场景上去做交互场景和交易场景，试图创造新的总量经济。",
        },
        {
          id: 1,
          title: "愿景",
          dec: "率先创造以艺术、文化娱乐为核心的全真元宇宙体验场景",
          tag: "VISIO",
        },
        {
          id: 2,
          title: "使命",
          dec: "将中国10亿+移动互联网用户通过技术转化为元宇宙用户。",
          tag: "MISS",
        },
        {
          id: 3,
          title: "价值观",
          dec: "价值观:共同参与、共同享有、共同治理、共同发展,更环保,更丰富,更持续,更多可能性。",
          tag: "VALL",
        },
      ],
      ecologyList: [
        {
          id: 0,
          title: "文化上链接虚拟与现实",
          dec: "从筹备创立C宇宙的第一天起，我们就思考：“C宇宙能为现实世界带来什么？”、“哪些方面可以补充现实世界中的不足？”、“如何将虚拟世界与现实世界联系起来，创造基于现实物理世界的虚拟平行世界？”在海量营销词汇的堆积下，社交媒体逐渐形成“万物皆可元宇宙”的“嘲讽式”论调，将“元宇宙”彻彻底底的变成了一个营销工具。而C宇宙要做的就是通过一系列技术与具体的操作办法，将形成的虚拟内容与现实世界相结合，以此坚持元宇宙“以虚强实”的发展导向，打造赋能千行百业的产业创新体系。我们坚持虚拟内容与虚拟世界一定是与现实世界有关联的，并不能独立存在。这便是C宇宙的核心文化——链接虚拟与现实。",
        },
        {
          id: 1,
          title: "产品上链接实物与NFT",
          dec: "一个NFT对应一个实物，这里泛指产品单价值不高或流通性较强的产品，具备一定的使用功能，我们将采取点对点式的发售形式（包括但不限于服装、潮玩、美妆、门票、景区标志性景点纪念品等）。一点对多的实物与NFT链接形式。一个实物对应多个NFT，这里泛指产品单价值高或流通性较弱的产品，不具备具体的使用功能，但又较强的投资价值，我们将采取点对多的发售形式（包括但不限于艺术收藏品、古董、金融衍生品等等）。C宇宙坚持虚拟世界里的NFT一定要对应现实世界中的实物（实际体验），它的产品将作为现实世界中产品的补充和升级而存在。",
        },
        {
          id: 2,
          title: "业务上链接更多可能性",
          dec: "为各种各样的生态提供一个交易场景，以推动对实体经济虚实融合、以虚强实的赋能带动作用，让大家都能从中获益是其存在的核心意义。B、C端客户的标准销售业务。对于天然适配的B、C端客户（艺术家、服装品牌、潮玩品牌等），我们提供标准销售业务和玩法。以销售为核心指标来进行链接，提供销售平台。B、C端客户的非标准服务业务。对于需要深度定制销售场景的非标准客户（景区、商场、金融机构等），我们提供非标准化的技术解决与服务方案。以用户需求为核心指标来进行链接，提供技术服务解决方案。",
        },
      ],
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.watchScroll);
    window.addEventListener("scroll", this.getScrollTop);
  },
  methods: {
    handleNav(index) {
      this.navCurrent = index;
      console.log(this.navCurrent);
      this.$el.querySelector(`#${"abc" + index}`).scrollIntoView({
        behavior: "auto",
        block: "end",
      });
    },
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
      if (scrollTop > 150) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    getScrollTop() {
      var scroll_top = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scroll_top = document.documentElement.scrollTop;
      } else if (document.body) {
        scroll_top = document.body.scrollTop;
      }
      console.log(scroll_top);
      switch (true) {
        case scroll_top <= 200:
          this.navCurrent = 0;
          break;
        case scroll_top >= 1300 && scroll_top <= 1900:
          this.navCurrent = 1;
          break;
        case scroll_top >= 1700 && scroll_top <= 2500:
          this.navCurrent = 2;
          break;
        case scroll_top >= 2500 && scroll_top <= 2800:
          this.navCurrent = 3;
          break;
        case scroll_top >= 3000 && scroll_top <= 3700:
          this.navCurrent = 4;
          break;
        case scroll_top >= 4000:
          this.navCurrent = 5;
      }
    },
    lookBook() {
      window.location.href =
        "http://ccm-glb.oss-cn-beijing.aliyuncs.com/banner/1648202709208_ccm";
    },
  },
};
</script>

<style lang="less" scoped>
.active-a{
  color: #000;
  text-decoration-line: none;
}
// 固定顶部
.navBarWarp {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #ececec;
  //     width: 100%;
}
.navActive {
  color: #ff744d !important;
  border-bottom: 1px solid #ff744d;
  // padding-bottom: 10px !important;
}
.container {
  width: 1200px;
  margin: 0 auto;
}
.header-box {
  display: flex;
  align-items: center;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  .header-left {
  }
  .header-right {
    display: flex;
    align-items: center;
    margin-left: 150px;
    ul {
      display: flex;
      li {
        height: 20px;
        margin: 0 30px;
        //    padding: 5px 0 0;
        padding: 10px 0;
        cursor: pointer;
      }
      li:hover {
        border-bottom: 1px solid #ff744d;
      }
    }
    .language {
      border: 1px solid #ccc;
      padding: 5px 10px;
      margin-left: 10px;
      img {
        margin-right: 5px;
      }
      .center {
        padding: 0 5px;
      }
    }
  }
}
.introduce {
  margin-top: 240px;
  p:first-child {
    font-family: B;
    font-size: 99.5556px;
    margin: 0;
  }
  p:nth-of-type(2) {
    font-family: Bold;
    font-size: 40px;
    margin: 0 0 43px;
  }
  p:last-child {
    font-family: "R";
    font-size: 18px;
  }
}
.Creative {
  width: 957px;
  height: 248px;
  font-family: "Bold";
  font-weight: 700;
  font-size: 147px;
  color: #ffffff;
  text-align: center;
  margin: 400px auto 0;
}
.brief {
  margin-top: 300px;
  > p:first-child {
    font-family: "B";
    font-size: 34px;
  }
  .info {
    width: 619px;
    height: 90px;
    font-family: "R";
    font-size: 18px;
    line-height: 30px;
  }
  .look {
    width: 172px;
    height: 46px;
    background: #7759ee;
    border-radius: 160px;
    border: 1px solid transparent;
    font-family: "R";
    font-size: 16px;
    color: #ffffff;
  }
  .brief-list {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    .list-one {
      width: 286px;
      height: 301px;
      background: rgba(255, 255, 255, 0.31);
      backdrop-filter: blur(15px);
      border-radius: 20px;
      > p:first-child {
        font-style: Bold;
        font-weight: 700;
        font-size: 20px;
        padding: 50px 34px 0;
      }
      > p:last-child {
        font-family: "R";
        font-size: 14px;
        padding: 0 34px;
        color: #0d0b15;
      }
    }
  }
}
.ecology {
  margin-top: 100px;
  > p:first-child {
    font-family: "B";
    font-size: 34px;
  }
  .info {
    width: 619px;
    height: 90px;
    font-family: "R";
    font-size: 18px;
    line-height: 30px;
  }
  .look {
    width: 172px;
    height: 46px;
    background: #7759ee;
    border-radius: 160px;
    border: 1px solid transparent;
    font-family: "R";
    font-size: 16px;
    color: #ffffff;
  }
  .ecology-box {
    display: flex;
    justify-content: space-between;
  }
  .ecology-list {
    width: 380px;
    height: 564px;
    background: rgba(213, 209, 255, 0.5);
    backdrop-filter: blur(12px);
    border-radius: 20px;
    margin-top: 45px;
    > p:first-child {
      font-family: "B";
      font-size: 20px;
      padding: 71px 33px 0;
    }
    > p:last-child {
      font-family: "R";
      font-size: 14px;
      padding: 34px 33px;
      line-height: 28px;
      padding: 34px;
    }
  }
}
.core {
  margin-top: 123px;
  .core-header {
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    .core-left {
      > p:first-child {
        font-family: "B";
        font-size: 34px;
      }
      > p:last-child {
        width: 486px;
        font-family: "R";
        font-size: 18px;
      }
    }
    .core-right {
      > p:first-child {
        font-family: "B";
        font-size: 34px;
      }
      > p:last-child {
        width: 668px;
        font-family: "R";
        font-size: 18px;
      }
    }
  }
  .main-text {
    font-family: "B";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    margin-top: 70px;
  }
  .table {
    width: 1300px;
    margin: 0 auto;
    table {
      text-align: center;
      // border-spacing: 0;
      thead {
        th {
          width: 230px;
          height: 74px;
          background: rgba(213, 209, 255, 0.5);
          backdrop-filter: blur(12px);
        }
      }
      tbody {
        tr {
          td:first-child {
            font-family: "R";
            font-size: 18px;
          }
          td {
            width: 230px;
            height: 74px;
            background: rgb(255, 255, 255);
            backdrop-filter: blur(15px);
            font-family: "R";
            font-size: 14px;
          }
        }
      }
    }
  }
}
.team {
  margin-top: 267px;
  > p:first-child {
    font-family: "B";
    font-size: 34px;
  }
  .team-message {
    width: 623px;
    height: 120px;
    font-family: "R";
    font-size: 18px;
    line-height: 30px;
  }
  .examples {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    .examples-left {
      width: 500px;
      height: 471px;
      background: rgba(255, 255, 255, 0.31);
      backdrop-filter: blur(15px);
      border-radius: 20px;
      padding: 50px 36px 0;
      > p {
        font-family: "B";
        font-weight: 700;
        font-size: 20px;
        span {
          font-family: "R";
          font-size: 14px;
          padding-left: 20px;
        }
      }
      .examples-box {
        display: flex;
        margin-top: 25px;
        p {
          width: 507px;
          margin: 0;
          font-family: "R";
          font-size: 14px;
          line-height: 28px;
        }
        img {
          width: 17px;
          height: 17px;
          margin-right: 15px;
          margin-top: 5px;
        }
      }
    }
    .examples-right {
      width: 500px;
      height: 471px;
      background: rgba(255, 255, 255, 0.31);
      backdrop-filter: blur(15px);
      border-radius: 20px;
      padding: 50px 36px 0;
      > p {
        font-family: "B";
        font-weight: 700;
        font-size: 20px;
        span {
          font-family: "R";
          font-size: 14px;
          padding-left: 20px;
        }
      }
      .examples-box {
        display: flex;
        margin-top: 25px;
        p {
          width: 507px;
          margin: 0;
          font-family: "R";
          font-size: 14px;
          line-height: 28px;
        }
        img {
          width: 17px;
          height: 17px;
          margin-right: 15px;
          margin-top: 5px;
        }
      }
    }
  }
}
.media {
  width: 1046px;
  height: 290px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(24px);
  border-radius: 20px;
  margin: 57px auto;
  p {
    font-family: "Bold";
    font-weight: 700;
    font-size: 34px;
    text-align: center;
    padding-top: 103px;
  }
  .media-item {
    display: flex;
    justify-content: space-around;
    padding: 0 198px;
  }
  .media-box {
    text-align: center;
    font-family: "R";
    font-size: 18px;
    img {
      width: 60px;
      height: 60px;
    }
  }
}
.footer {
  text-align: center;
  height: 60px;
  line-height: 60px;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(24px);
  font-family: "B";
  font-size: 14px;
}
// body{
//      position: relative;
// }
.share {
  position: fixed;
  right: 0;
  top: 200px;
  width: 205px;
  height: 253px;
  background: rgba(213, 209, 255, 0.5);
  backdrop-filter: blur(12px);
  border-radius: 20px;

  div {
    width: 136px;
    height: 48px;
    text-align: center;
    margin: 20px auto 0;
    font-family: "B";
    font-size: 16px;
  }
  .img {
    width: 155px;
    height: 155px;
    background: #ffffff;
    margin: 20px auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
