<template>
  <div id="app">
    <Home/>
  </div>
</template>

<script>
import Home from '@/components/Home'
export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
body,html{
  margin: 0;
  padding: 0;
  width: 100%; 
  height: 100%;
  background:url('../src/assets/bg.png') no-repeat;
  background-size: cover;
}
ul li{
  list-style: none;
}
@font-face {
  font-family: Bold;
  src: url('../src/font/Syncopate-Bold.ttf');
}
@font-face {
  font-family: R;
  src: url('../src/font/OPPOSans-R-2.ttf');
}
@font-face {
  font-family: B;
  src: url('../src/font/OPPOSans-B-2.ttf');
}
</style>
